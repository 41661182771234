// Even though we have a `global-error.tsx` it is still recommended to have a root error.tsx:
// https://nextjs.org/docs/app/building-your-application/routing/error-handling

"use client";

// Error components must be Client Components
import { GenericErrorMessage } from "@/components/GenericErrorMessage/GenericErrorMessage.component";
import { useCaptureException } from "@/hooks/use-capture-exception.hook";
export default function Error({
  error,
  reset
}: {
  readonly error: Error & {
    readonly digest?: string;
  };
  readonly reset: () => void;
}) {
  useCaptureException(error);
  return <div data-sentry-component="Error" data-sentry-source-file="error.tsx">
      <GenericErrorMessage reset={reset} data-sentry-element="GenericErrorMessage" data-sentry-source-file="error.tsx" />
    </div>;
}